import React from "react"
import PropTypes from "prop-types"

import { Grid } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { StyledSegment, StyledPad } from "./Segment.styled"

import { getBackground } from "./Segment.api"

const Segment = ({ children, variant }) => {
  const theme = useTheme()
  const bg = getBackground(theme, variant)
  return (
    <StyledSegment sx={{ background: bg }}>
      <Grid container>
        <Grid item xs={12}>
          <StyledPad>{children}</StyledPad>
        </Grid>
      </Grid>
    </StyledSegment>
  )
}

Segment.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary"]),
}

export default Segment
