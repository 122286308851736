const getBackground = (_theme, _variant) => {
  let bgColor = null
  switch (_variant) {
    case "primary":
      bgColor = _theme.palette.primary.main
      break
    case "secondary":
      bgColor = _theme.palette.secondary.main
      break
    default:
      bgColor = "white"
  }
  return bgColor
}

export { getBackground }
