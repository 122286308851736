import { Box } from "@mui/material"
import { styled } from "@mui/system"

const StyledSegment = styled(Box)(({ theme }) => ({
  padding: "6rem 0",
}))

const StyledPad = styled(Box)(({ theme }) => ({
  padding: 2,
}))

export { StyledSegment, StyledPad }
